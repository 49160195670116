import { createContext, useState } from 'react';

export const LocalStorage = createContext(null);

export default ({ children }) => {
    const [isSideMenuActive, setIsSideMenuActive] = useState(false);
    const [navigationList] = useState([
        { to: '/rgb', displayText: 'PROF. R GEETHA BALAKRISHNA' },
        { to: '/area-of-interest', displayText: 'AREAS OF INTEREST' },
        {
            displayText: 'PUBLICATIONS', sub: [
                { to: '/publications/Publications', displayText: 'Publications' },
                { to: '/publications/Book Chapters', displayText: 'Book Chapters' },
                { to: '/publications/Patents', displayText: 'Patents' },
                { to: '/publications/Conference Presentations', displayText: 'Conference Presentations' }
            ]
        },
        {
            displayText: 'GROUP MEMBERS', sub: [
                { to: '/our-research-group/Faculties', displayText: 'Faculties' },
                { to: '/our-research-group/Postdocs-Research Associates', displayText: 'Postdocs/Research Associates' },
                { to: '/our-research-group/PhD Students', displayText: 'PhD Students' },
                { to: '/our-research-group/PhD Alumni', displayText: 'PhD Alumni' }
            ]
        },
        { to: '/facilities', displayText: 'FACILITIES' },
        {
            displayText: 'GALLERY', sub: [
                { to: '/gallery/Awards', displayText: 'Awards' },
                { to: '/gallery/Events', displayText: 'Events' },
                { to: '/gallery/Lab Outings', displayText: 'Lab Outings' }
            ]
        }
    ]);
    const store = {
        isSideMenuActive: [isSideMenuActive, setIsSideMenuActive],
        navigationList: [navigationList]
    }

    return (<LocalStorage.Provider value={store}>{children}</LocalStorage.Provider>);
}