import { useContext } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Dropdown, DropdownItem, DropdownMenu, Menu, MenuItem } from 'semantic-ui-react';
import { isUndefinedOrNull } from '../Service/Helpers';
import { LocalStorage } from './../Service/LocalStorage';
function NavigationList() {
    const location = useLocation();
    const { isSideMenuActive: [, setIsSideMenuActive] } = useContext(LocalStorage);
    const { navigationList: [navigationList] } = useContext(LocalStorage);
    const onNavigate = () => {
        setIsSideMenuActive(false);
    }
    return (
        <>
            <Menu className='headerMenuContainer'>
                {navigationList.map((menu) => {
                    if (!isUndefinedOrNull(menu.sub) && menu.sub.length > 0) {
                        return (
                            <>
                                <Dropdown item text={menu.displayText} key={menu.displayText} className='item headerMenu' closeOnBlur closeOnEscape closeOnChange upward={false}>
                                    <DropdownMenu className='subMenuContainer' id={menu.displayText.replaceAll(' ', '')}>
                                        {menu.sub.map((subMenu) => {
                                            return (
                                                <>
                                                    <DropdownItem to={!location.pathname.includes(subMenu.to.split('/').splice(1).join('/')) ? subMenu.to : '#'} as={NavLink} className='item headerSubMenu' key={subMenu.displayText} onClick={() => onNavigate()}>
                                                        {subMenu.displayText}
                                                    </DropdownItem>
                                                </>
                                            )
                                        })}
                                    </DropdownMenu>
                                </Dropdown>

                            </>
                        )
                    }
                    return (
                        <>
                            <MenuItem to={!location.pathname.includes(menu.to.split('/')[1]) ? menu.to : '#'} as={NavLink} className='item headerMenu' key={menu.displayText} onClick={() => onNavigate()}>
                                {menu.displayText}</MenuItem>
                        </>
                    )
                })}
            </Menu>
        </>
    )
}

export default NavigationList;