import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'semantic-ui-css/semantic.min.css'
import { BrowserRouter } from 'react-router-dom'
import LocalProvider from './Service/LocalStorage';
import { Route, Routes } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <LocalProvider>
      <Routes>
        <Route path='*' element={<App />} />
      </Routes>
    </LocalProvider>
  </ BrowserRouter>
);
reportWebVitals();
