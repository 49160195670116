import { useEffect, useState } from 'react';
import { Header, Image } from 'semantic-ui-react';
import { isUndefinedOrNull, sortArrayByElement } from '../Service/Helpers';
import galleryData from './../Assets/sampleJSON/gallery.json';
import GalleryModal from './../Components/GalleryModal';
import { NavLink, useParams } from 'react-router-dom';

function Gallery() {
    const { category } = useParams();
    const [selectedFilter, setSelectedFilter] = useState({});
    const [dataToDisplay] = useState(sortArrayByElement(galleryData.data, 'order'));
    const [listToDisplay, setListToDisplay] = useState([]);
    const [selectedImage, setSelectedImage] = useState();
    
    useEffect(() => {
        let cat = !isUndefinedOrNull(category) || dataToDisplay.map(x => x.category).includes(category) ? category : 'Awards';
        let displayData = dataToDisplay.find(x => x.category === cat);
        setSelectedFilter(displayData);
        setListToDisplay(sortArrayByElement(displayData.list, 'order'));
        document.getElementById('header').scrollIntoView({ behavior: 'smooth' });
    }, [dataToDisplay, category]);

    const onFilterClick = (selFilterOpt) => {
        setSelectedFilter(selFilterOpt);
        setListToDisplay(sortArrayByElement(selFilterOpt.list, 'order'));
    }

    return (
        <>
            <div className='page' >
                <div className='pageHeaderBackground'>
                    <Image src={require('./../Assets/Backgrounds/galleryBg.jpg')} alt='' className='pageHeaderImg' />
                    <Header as='h1' className='pageHeader'>GALLERY</Header>
                </div>
                <div className='galleryContainer pageSegment'>
                    <div className='filterContainer'>
                        {dataToDisplay.map((filterOpts) => {
                            return (
                                <>
                                    <NavLink to={'/gallery/' + filterOpts.category} className={selectedFilter.category === filterOpts.category ? 'active filterOptions' : 'filterOptions'} onClick={() => onFilterClick(filterOpts)} key={filterOpts.category}>{filterOpts.category}</NavLink>
                                </>
                            )
                        })}
                    </div>
                    <div className='imageContainer'>
                        {listToDisplay.map((img) => {
                            return (
                                <>
                                    <div className='imageDetails' key={img.image}>
                                        <Image src={require('./../Assets/Images/' + img.image)} alt='' className='galleryImage' onClick={() => setSelectedImage(img)} />
                                        <div className='imageTitle' dangerouslySetInnerHTML={{ __html: img.title }}></div>
                                    </div>
                                </>
                            )
                        })}
                    </div>
                </div>
                {!isUndefinedOrNull(selectedImage) &&
                    <GalleryModal galleryData={listToDisplay} selectedImage={selectedImage} onCloseModal={() => setSelectedImage()} />
                }
            </div>
        </>
    );
}

export default Gallery;