import { useState } from 'react';
import { Header, Image } from 'semantic-ui-react';
import latestNewsData from '../Assets/sampleJSON/latestNews.json';
import { isUndefinedOrNull, sortArrayByElement } from '../Service/Helpers';
function LatestNews() {
    const [dataToDisplay] = useState(sortArrayByElement(latestNewsData.data, 'date').reverse());
    const [years] = useState(Array.from(new Set(latestNewsData.data.map((news) => { return (Number(news.date.split(', ')[1])) }).sort().reverse())));

    const onFilterClick = (year) => {
        document.getElementById(year.toString()).scrollIntoView({ behavior: 'smooth' });
    }
    return (
        <>
            <div className='page'>
                <div className='pageHeaderBackground'>
                    <Image src={require('./../Assets/Backgrounds/latestNewsBg.jpg')} alt='' className='pageHeaderImg' />
                    <Header as='h1' className='pageHeader'>LATEST NEWS</Header>
                </div>
                <div className='pageSegment'>
                    <div className='filterContainer'>
                        {years.map((y) => {
                            return (
                                <>
                                    <div className='filterOptions' onClick={() => onFilterClick(y)} key={y}>{y}</div>
                                </>
                            )
                        })}
                    </div>
                    <div className='latestNewsSegment'>
                        {years.map((yr) => {
                            return (
                                <>
                                    <div className='newsYearContainer' key={yr} id={yr.toString()}>
                                        <div className='newsYear'>{yr}</div>
                                        <div className='newsContainer'>
                                            {sortArrayByElement(dataToDisplay.filter(x => x.date.includes(yr.toString())), 'order').map((news) => {
                                                return (
                                                    <>
                                                        <div key={news.title}>
                                                            {(!isUndefinedOrNull(news.navigateTo) && news.navigateTo !== '' && news.navigateTo !== '/latest-news') &&
                                                                <>
                                                                    <a href={news.navigateTo} className='latestNewsTitle' dangerouslySetInnerHTML={{ __html: news.title }}></a>
                                                                    <br />
                                                                </>
                                                            }
                                                            {(isUndefinedOrNull(news.navigateTo) || news.navigateTo === '' || news.navigateTo === '/latest-news') &&
                                                                <>
                                                                    <div className='latestNewsTitle' dangerouslySetInnerHTML={{ __html: news.title }}></div>
                                                                </>
                                                            }
                                                            <i>{news.date}</i>
                                                        </div>
                                                    </>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </>
                            )
                        })}
                    </div>
                </div>
            </div>
        </>
    );
}

export default LatestNews;