import { useEffect, useState } from 'react';
import { Header, Image } from 'semantic-ui-react';
import groupMembersData from '../Assets/sampleJSON/groupMembers.json';
import ProfileModal from '../Components/ProfileModal';
import { isUndefinedOrNull, sortArrayByElement } from '../Service/Helpers';
import { useParams } from 'react-router-dom';

function GroupMembers() {
    const { category } = useParams();
    const [dataToDisplay] = useState(groupMembersData.data);
    const [viewProfile, setViewProfile] = useState();
    useEffect(() => {
        if (!isUndefinedOrNull(category)){
            document.getElementById(category).scrollIntoView({ behavior: 'smooth' });
        }
    }, [dataToDisplay, category])
    return (
        <>
            <div className='page'>
                <div className='pageHeaderBackground'>
                    <Image src={require('./../Assets/Backgrounds/groupMembersBg.jpg')} alt='' className='pageHeaderImg' />
                    <Header as='h1' className='pageHeader'>GROUP MEMBERS</Header>
                </div>
                <div className='pageSegment'>
                    <div className='groupMembersImageContainer'>
                        {sortArrayByElement(dataToDisplay, 'order').map((resGrp) => {
                            return (
                                <>
                                    <div>
                                        <Header as='h1' className='researchGrpCat' id={resGrp.category.replaceAll('\'', '').replaceAll('/', '-')}>{resGrp.category}</Header>
                                        {resGrp.category !== 'PhD Alumni' &&
                                            <div className='membergGroup'>
                                                {sortArrayByElement(resGrp.members).map((member) => {
                                                    return (
                                                        <>
                                                            <div className='imageDetails' key={member.name}>
                                                                <Image src={require('./../Assets/Images/' + member.image)} alt='' className='galleryImage' />
                                                                <div className='groupMembersImageDetails'>
                                                                    <div className='memberDetails'>
                                                                        <div className='memberRole'>{member.role}</div>
                                                                        <Header as='h3' className='memberName'>{member.name}</Header>
                                                                        <div className='memberField' dangerouslySetInnerHTML={{ __html: member.researchTitle }}>
                                                                        </div>
                                                                    </div>
                                                                    {member.profileRequired &&
                                                                        <div onClick={() => setViewProfile(member)} className='viewMore'><u><i>VIEW MORE</i></u></div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                })}
                                            </div>
                                        }
                                        {resGrp.category === 'PhD Alumni' &&
                                            <>
                                                {sortArrayByElement(resGrp.members, 'order').map((member) => {
                                                    return (
                                                        <>
                                                            <div className='PhD alumni' key={member.name}>{member.name}</div>
                                                        </>
                                                    )
                                                })}
                                            </>
                                        }
                                    </div>
                                </>
                            )
                        })}
                    </div>
                </div>
                {!isUndefinedOrNull(viewProfile) &&
                    <ProfileModal profileData={viewProfile} onCloseModal={() => setViewProfile()} />
                }
            </div>
        </>
    );
}

export default GroupMembers;