import { useEffect, useState } from 'react';
import { Header, Image } from 'semantic-ui-react';
import { sortArrayByElement } from '../Service/Helpers';
import facilitiesData from './../Assets/sampleJSON/facilities.json';

function Facilities() {
    const [dataToDisplay] = useState(facilitiesData.data);
    useEffect(() => {
        document.getElementById('header').scrollIntoView({ behavior: 'smooth' });
    }, [])
    return (
        <>
            <div className='page'>
                <div className='pageHeaderBackground'>
                    <Image src={require('./../Assets/Backgrounds/facilitiesBg.jpg')} alt='' className='pageHeaderImg' />
                    <Header as='h1' className='pageHeader'>FACILITIES</Header>
                </div>
                <div className='facilityEmail'><Header as='h4' className='facilityEmailLabel'>Contact Email: &nbsp;&nbsp;</Header>{dataToDisplay.contactEmail}</div>
                <div className='facilitiesContainer pageSegment'>
                    {sortArrayByElement(dataToDisplay.list,'order').map((items) => {
                        return (
                            <>
                                <div className='facilities' key={items.name}>
                                    <Header as='h2' className='facilitiesHeader'> {items.name}</Header>
                                    <div className='facilitiesDetails'>
                                        <Image src={require('./../Assets/Images/' + items.image)} alt='' className='facilitiesImg' />
                                        <div className='facilitiesContent' dangerouslySetInnerHTML={{ __html: items.description }}>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    })}
                </div>
            </div>
        </>
    );
}

export default Facilities;