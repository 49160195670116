import { useState } from 'react';
import { Header } from 'semantic-ui-react';

function AppFooter() {
    const [year] = useState((new Date()).getFullYear());
    return (
        <footer className='appFooter'>
            <div className='appFooterContaint'>
                <Header as='h2' className='footerHeader'>RGB's Photo & Electrocatalysis Lab</Header>
                <span>br.geetha@jainuniversity.ac.in</span>
                <span>©{year} by RGB's Photo and Electrocatalysis Lab.</span>
            </div>
        </footer>
    );
}

export default AppFooter;
