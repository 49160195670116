import { useEffect, useState } from 'react';
import { Header, Image, Menu, MenuItem, Segment } from 'semantic-ui-react';
import { isUndefinedOrNull, sortArrayByElement } from '../Service/Helpers';
import publicationsData from './../Assets/sampleJSON/publication.json';
import { NavLink, useParams } from 'react-router-dom';
function Publications() {
    const { category } = useParams();
    const [activeItem, setActiveItem] = useState();
    const [menuList, setMenuList] = useState([]);
    const [dataToDisplay] = useState(sortArrayByElement(publicationsData.data, 'order'));
    const [filterOptions, setFilterOptions] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState();
    const [listToDisplay, setListToDisplay] = useState([]);
    useEffect(() => {
        let menus = dataToDisplay.map(x => x.name);
        let cat = category;
        if (isUndefinedOrNull(category) || !menus.includes(category)) {
            window.history.pushState({}, '', '/publications/Publications');
            cat = 'Publications';
        }
        setMenuList(menus);
        if (cat === 'Publications') {
            let filterList = [...new Set(dataToDisplay[0].filterOptions, 'order')]
            setSelectedFilter(filterList[0]);
            setFilterOptions(filterList);
            setListToDisplay(sortArrayByElement(dataToDisplay[0].list.filter(x => filterList[0].values.includes(x.year)), 'order').reverse());
            setActiveItem(dataToDisplay[0]);
        }
        else {
            let displayData = dataToDisplay.find(x => x.name === cat);
            setActiveItem(displayData);
            setListToDisplay(sortArrayByElement(displayData.list, 'order'));
        }
        document.getElementById('header').scrollIntoView({ behavior: 'smooth' });
    }, [dataToDisplay, category]);

    const onFilterClick = (filterItem) => {
        setSelectedFilter(filterItem);
        setListToDisplay(sortArrayByElement(dataToDisplay[0].list.filter(x => filterItem.values.includes(x.year)), 'order').reverse());
    }
    const onMenuSelection = (menu) => {
        let menuDetails = dataToDisplay.find(x => x.name === menu);
        setActiveItem(menuDetails);
        if (menuDetails.name === 'Publications') {
            let filterList = [...new Set(menuDetails.filterOptions)]
            setSelectedFilter(filterList[0]);
            setFilterOptions(filterList);
            setListToDisplay(sortArrayByElement(menuDetails.list.filter(x => filterList[0].values.includes(x.year)), 'order').reverse());
        }
        else {
            setListToDisplay(sortArrayByElement(menuDetails.list, 'order'));
        }
    }
    return (
        <>
            <div className='page'>
                <div className='pageHeaderBackground'>
                    <Image src={require('./../Assets/Backgrounds/publicationsBg.jpg')} alt='' className='pageHeaderImg' />
                    <Header as='h1' className='pageHeader'>PUBLICATIONS</Header>
                </div>
                <div className='pageSegment'>
                    <Menu fluid attached='top' tabular className='publicationsMenu'>
                        {menuList.map((menu) => {
                            return (
                                <>
                                    <MenuItem
                                        to={'/publications/' + menu}
                                        active={activeItem.name === menu}
                                        key={menu}
                                        as={NavLink}
                                        onClick={() => onMenuSelection(menu)}
                                    >{menu}</MenuItem>
                                </>
                            )
                        })}
                    </Menu>
                    <Segment attached='bottom'>
                        {!isUndefinedOrNull(activeItem) && activeItem.name === 'Publications' &&
                            <div className='filterContainer'>
                                {filterOptions.map((y) => {
                                    return (
                                        <>
                                            <div className={selectedFilter.label === y.label ? 'active filterOptions' : 'filterOptions'} onClick={() => onFilterClick(y)} key={y.label}>{y.label}</div>
                                        </>
                                    )
                                })}
                            </div>
                        }
                        <div className='listContainerWithYear'>
                            {!isUndefinedOrNull(activeItem) &&
                                <>
                                    {activeItem.name === 'Publications' && selectedFilter.values.sort().reverse().map((yr) => {
                                        return (
                                            <>
                                                <div key={yr}>
                                                    <Header as='h2'>{yr}</Header>
                                                    <div className='listContainer'>
                                                        {listToDisplay.filter(x => x.year === yr).map((listData) => {
                                                            return (
                                                                <>
                                                                    <div className='itemDetails' key={listData.title}>
                                                                        <span className='listNumber'>{listData.order}.</span>
                                                                        <span dangerouslySetInnerHTML={{ __html: listData.title }}></span>
                                                                        <br/>
                                                                        <span>(DOI: <a href={listData.DOILink} target='_blank' rel='noreferrer'>{listData.DOIText}</a>)</span>
                                                                    </div>
                                                                </>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })}
                                    {activeItem.name !== 'Publications' &&
                                        <div className='listContainer'>
                                            {listToDisplay.map((listData) => {
                                                return (
                                                    <>
                                                        <div className='itemDetails' key={listData.title}>
                                                            <span className='listNumber'>{listData.order}.</span>
                                                            <span dangerouslySetInnerHTML={{ __html: listData.title }}></span>
                                                        </div>
                                                    </>
                                                )
                                            })}
                                        </div>
                                    }
                                </>
                            }
                        </div>
                    </Segment>
                </div>
            </div>
        </>
    );
}

export default Publications;