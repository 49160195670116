import { useEffect, useState } from 'react';
import { Button, Header, Icon, Image } from 'semantic-ui-react';
import { sortArrayByElement } from '../Service/Helpers';
import rgbData from './../Assets/sampleJSON/rgb.json';
function RGB() {
    const [dataToDisplay] = useState(rgbData.data);
    useEffect(() => {
        document.getElementById('header').scrollIntoView({ behavior: 'smooth' });
    }, [])
    return (
        <>
            <div className='page' id='page'>
                <div className='pageHeaderBackground'>
                    <Image src={require('./../Assets/Backgrounds/rgbBg.jpg')} alt='' className='pageHeaderImg' />
                    <Header as='h1' className='pageHeader rgbHeader' >PROF. R GEETHA BALAKRISHNA</Header>
                </div>
                <div className='pageSegment'>
                    <div className='rgbDetailsAndRecognitions'>
                        <div className='rgbImageAndDetails'>
                            <Image src={require('./../Assets/Images/' + dataToDisplay.image)} alt='' className='rgbImage' />
                            <div className='rgbDetails'>
                                <Header as='h2' className='rgbName'>{dataToDisplay.name}</Header>
                                <div className='locationDetails'>
                                    <div className='postionAndProfileBtn'><b>{dataToDisplay.position}</b><Button size='mini' color='blue' content='Profile' icon='download' labelPosition='right' as='a' href={require('./../Assets/doc/' + dataToDisplay.doc)} download={dataToDisplay.doc}></Button></div>
                                    <div>{dataToDisplay.department}</div>
                                    <div>{dataToDisplay.university}</div>
                                    <div>{dataToDisplay.location}</div>
                                </div>
                                <div><Icon name='phone' />{dataToDisplay.phone}</div>
                                <div><Icon name='mail' />{dataToDisplay.email}</div>
                            </div>
                        </div>
                        <div className='recognitions'>
                            <Header as='h2'>Recognitions</Header>
                            <div className='recognitionsContainer'>
                                <ul className='recognitionsDetails'>
                                    {sortArrayByElement(dataToDisplay.recognitions, 'order').reverse().map((recognitions) => {
                                        return (
                                            <>
                                                <li className='recognitionsName' key={recognitions.title} dangerouslySetInnerHTML={{ __html: recognitions.title }}></li>
                                            </>
                                        )
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='rgbDescription' dangerouslySetInnerHTML={{ __html: dataToDisplay.description }}></div>
                    <div className='positionDetailsConatiner'>
                        <div className='position'>
                            <Header as='h2'>Academic Positions</Header>
                            <div className='positionContainer'>
                                {sortArrayByElement(dataToDisplay.academicPositions, 'from').reverse().map((academicPos) => {
                                    return (
                                        <>
                                            <div className='positionSegment' key={academicPos.from + ' ' + academicPos.to}>
                                                <div className='positionYear'>{academicPos.from} - {academicPos.to}</div>
                                                <div className='positionDetails'>
                                                    <Header as='h3' className='positionText'>{academicPos.position}</Header>
                                                    <div dangerouslySetInnerHTML={{ __html: academicPos.details }}></div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                })}
                            </div>
                        </div>
                        <div className='position'>
                            <Header as='h2'>Research Positions</Header>
                            <div className='positionContainer'>
                                {sortArrayByElement(dataToDisplay.researchPositions, 'year').reverse().map((researchPos) => {
                                    return (
                                        <>
                                            <div className='positionSegment' key={researchPos.year}>
                                                <div className='positionYear'>{researchPos.year}</div>
                                                <div className='positionDetails'>
                                                    <Header as='h3' className='positionText'>{researchPos.position}</Header>
                                                    <div dangerouslySetInnerHTML={{ __html: researchPos.details }}></div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    <div className='education'>
                        <Header as='h2'>Education</Header>
                        <div className='educationDetails'>
                            <ul>
                                {sortArrayByElement(dataToDisplay.education, 'order').map(edu => {
                                    return (
                                        <>
                                            <li>{edu.title}</li>
                                        </>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                    <div className='projectsHandled'>
                        <Header as='h2'>Projects handled</Header>
                        <div className='projectsHandledDetails'>
                            {sortArrayByElement(dataToDisplay.projectsHandled, 'order').map((compProj) => {
                                return (
                                    <>
                                        <div className='itemDetails' key={compProj.title}>
                                            <span className='listNumber'>{compProj.order}.</span>
                                            <span dangerouslySetInnerHTML={{ __html: compProj.title }}></span>
                                        </div>
                                    </>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default RGB;