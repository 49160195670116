import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Header, Image } from 'semantic-ui-react';
import { sortArrayByElement } from '../Service/Helpers';
import homeData from './../Assets/sampleJSON/home.json';
import latestNewsData from '../Assets/sampleJSON/latestNews.json';
import publicationsData from '../Assets/sampleJSON/publication.json';

function Home() {
    const [dataToDisplay] = useState(homeData.data);
    const [latestNewsToDisplay] = useState((sortArrayByElement(latestNewsData.data, 'date')).reverse().slice(0, 5));
    useEffect(() => {
        document.getElementById('header').scrollIntoView({ behavior: 'smooth' });
    }, []);

    return (
        <>
            <div className='page '>
                <div className='carsolContainer'>
                    <Image src={require('./../Assets/Images/banner.jpg')} alt='' className='carsolImg' />
                    <Header as='h1' className='pageHeader homePageHeader'>WELCOME<br />TO OUR GROUP</Header>
                </div>
                <div className='pageSegment'>
                    <div className='cardHolder'>
                        {sortArrayByElement(dataToDisplay.cardList, 'order').map((cards) => {
                            return (
                                <>
                                    <div className='card' key={cards.image}>
                                        <Image src={require('./../Assets/Images/' + cards.image)} alt='' className='cardImg' />
                                        <div className='cardDetails'>
                                            <Header as='h3' className='cardHeader'>{cards.header}</Header>
                                            <div dangerouslySetInnerHTML={{ __html: cards.description }}></div>
                                            <a href={cards.navigateTo}>Read more...</a>
                                        </div>
                                    </div>
                                </>
                            )
                        })}
                    </div>
                    <div className='selectedPublicationsAndLatestNews'>
                        <div className='selectedPublications'>
                            <Header as='h2'>Selected Publications</Header>
                            <div className='selectedPublicationsDetails'>
                                {sortArrayByElement(publicationsData.data.find(x => x.name = "Publications").list.filter(y => y.isSelected === true), 'year').reverse().map((selPub, index) => {
                                    return (
                                        <>
                                            <div className='itemDetails' key={selPub.title}>
                                                <span className='listNumber'>{index + 1}.</span>
                                                <span dangerouslySetInnerHTML={{ __html: selPub.title }}></span>
                                                <br />
                                                <span>(DOI: <a href={selPub.DOILink} target='_blank' rel='noreferrer'>{selPub.DOIText}</a>)</span>
                                            </div>
                                        </>
                                    );
                                })}
                            </div>
                        </div>
                        <div className='latestNewsAndAnnualReport'>
                            <div className='latestNews'>
                                <Header>Latest News</Header>
                                <div className='latestNewsContaier'>
                                    {latestNewsToDisplay.map((latestNews) => {
                                        return (
                                            <>
                                                <div className='latestNewsEvent'>
                                                    <NavLink to={latestNews.navigateTo} className='latestNewsTitle' dangerouslySetInnerHTML={{ __html: latestNews.title }}></NavLink>
                                                    <br />
                                                    <i>{latestNews.date}</i>
                                                </div>
                                            </>
                                        )
                                    })}
                                </div>
                                <a href='/latest-news'><i><u>View more...</u></i></a>
                            </div>
                            <div className='annualReport'>
                                <Header>Annual Reports</Header>
                                <div className='annualReportContaier'>
                                    {sortArrayByElement(dataToDisplay.annualReport, 'year').reverse().map((annualReport) => {
                                        return (
                                            <>
                                                <a href={require('./../Assets/doc/' + annualReport.doc)} download={annualReport.year}><u>{annualReport.year}</u></a>
                                            </>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='jobOpenings'>
                    <div className='jobOpeningsContainer'>
                        <Header as='h2'>Current Opportunities at RGB's Photo and Electrocatalysis Lab</Header>
                        <p className='jobOpeningsDetails'>
                            For Ph.D. & Post doc positions, please visit<br />
                            <a href='https://cnms.jainuniversity.ac.in/careers.htm' target='_blank' rel='noreferrer'>https://cnms.jainuniversity.ac.in/careers.htm</a>
                            <br />
                            <Button content='Contact us' primary onClick={() => window.location.href = '/contact'} />
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Home;