import { useEffect, useState } from 'react';
import { Header, Image, Icon } from 'semantic-ui-react';
import { sortArrayByElement, isUndefinedOrNull } from '../Service/Helpers';
import areasOfInterestData from '../Assets/sampleJSON/areasOfInterest.json';
import GalleryModal from './../Components/GalleryModal';

function AreasOfInterest() {
    const [dataToDisplay] = useState(sortArrayByElement(areasOfInterestData.data, 'order'));
    const [listToDisplay, setListToDisplay] = useState([]);
    const [selectedImage, setSelectedImage] = useState();
    const [time, setTime] = useState(0);
    const [showPublications, setShowPublications] = useState([]);
    useEffect(() => {
        document.getElementById('header').scrollIntoView({ behavior: 'smooth' });
    }, []);
    useEffect(() => {
        setInterval(() => {
            setTime((prev) => prev + 1);
        }, 5000);
    }, [])
    return (
        <>
            <div className='page'>
                <div className='pageHeaderBackground'>
                    <Image src={require('./../Assets/Backgrounds/areasOfInterestBg.jpg')} alt='' className='pageHeaderImg' />
                    <Header as='h1' className='pageHeader'>AREAS OF INTEREST</Header>
                </div>
                <div className='pageSegment'>
                    {dataToDisplay.map((areasOfInterestItem) => {
                        return (
                            <>
                                <div className='areasOfInterestContent' key={areasOfInterestItem.title}>
                                    <div className='areasOfInterestImgContainer'>
                                        <Image src={require('./../Assets/Images/' + areasOfInterestItem.image)} alt='' className='areasOfInterestImg' />
                                        <span className='areasOfInterestTitleContainer'>
                                            <Header as='h2' className='areasOfInterestTitle'>
                                                {areasOfInterestItem.title}
                                            </Header>
                                        </span>
                                    </div>
                                    <div className='motionPicturesContainer'>
                                        <div className="motionPictures">
                                            {areasOfInterestItem.relatedImages.map((img, i) => {
                                                return (
                                                    <>
                                                        {time % areasOfInterestItem.relatedImages.length === i &&
                                                            <Image src={require('./../Assets/Images/' + img.image)} alt='' className='relatedImages' onClick={() => { setSelectedImage(img); setListToDisplay(areasOfInterestItem.relatedImages) }} />

                                                        }
                                                    </>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    <div className='contentText'>
                                        <div className='mb-2' dangerouslySetInnerHTML={{ __html: areasOfInterestItem.description }}></div>
                                        <div className='relatedPublicationsContainer'>
                                            {sortArrayByElement(areasOfInterestItem.relatedPublications, 'order').map((pub, i) => {
                                                return (
                                                    <>
                                                        {!showPublications.includes(areasOfInterestItem.id) && i < 3 &&
                                                            <>
                                                                <div className='relatedPublications'>
                                                                    {!isUndefinedOrNull(pub.link) ?
                                                                        <a href={pub.link} target='_blank'>{pub.title}</a> : <a >{pub.title}</a>
                                                                    }
                                                                </div>
                                                                {i === 2 &&
                                                                    <a className='cursor-pointer' onClick={() => setShowPublications((arr) => [...arr, ...[areasOfInterestItem.id]])}><i><u>View more...</u></i></a>
                                                                }
                                                            </>
                                                        }
                                                        {showPublications.includes(areasOfInterestItem.id) &&
                                                            <>
                                                                <div className='relatedPublications'>
                                                                    {!isUndefinedOrNull(pub.link) ?
                                                                        <a href={pub.link} target='_blank'>{pub.title}</a>
                                                                        :
                                                                        <a >{pub.title}</a>
                                                                    }
                                                                </div>
                                                                {i === areasOfInterestItem.relatedPublications.length - 1 &&
                                                                    <a className='cursor-pointer' onClick={() => setShowPublications(showPublications.filter(x => x != areasOfInterestItem.id))}><i><u>View less...</u></i></a>
                                                                }
                                                            </>
                                                        }
                                                    </>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div >
                            </>
                        )
                    })}
                </div>
                {!isUndefinedOrNull(selectedImage) &&
                    <GalleryModal galleryData={listToDisplay} selectedImage={selectedImage} onCloseModal={() => setSelectedImage()} />
                }
            </div >
        </>
    );
}

export default AreasOfInterest;