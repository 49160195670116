import { useEffect, useState } from 'react';
import { Form, FormButton, FormGroup, FormInput, FormTextArea, Grid, GridColumn, GridRow, Header, Icon, Image } from 'semantic-ui-react';
import contactDetailsData from './../Assets/sampleJSON/contactDetails.json';
import { isUndefinedOrNull } from '../Service/Helpers';
function Contact() {
    const [dataToDisplay] = useState(contactDetailsData.data);
    useEffect(() => {
        document.getElementById('header').scrollIntoView({ behavior: 'smooth' });
    }, []);
    const onSend = () => {
        let name = document.getElementById('name')?.value;
        let email = document.getElementById('email')?.value;
        let phone = document.getElementById('phone')?.value;
        let address = document.getElementById('address')?.value;
        let subject = document.getElementById('subject')?.value;
        let message = document.getElementById('message')?.value;
        if (!isUndefinedOrNull(name) && !isUndefinedOrNull(email) && !isUndefinedOrNull(phone)
            && !isUndefinedOrNull(address) && !isUndefinedOrNull(subject) && !isUndefinedOrNull(message)) {
            let body = {
                name:name,
                email: email,
                phone: phone,
                address: address,
                subject: subject,
                message: message
            }
            console.log(body);
            document.getElementById('inputForm')?.reset();
        }
        else {
            alert("Fill required field to send.");
        }
    }
    return (
        <>
            <div className='page'>
                <div className='pageHeaderBackground'>
                    <Image src={require('./../Assets/Backgrounds/contactBg.jpg')} alt='' className='pageHeaderImg' />
                    <Header as='h1' className='pageHeader'>CONTACT</Header>
                </div>
                <div className='pageSegment contact'>
                    <div className='contactDetails'>
                        <Grid padded>
                            <GridRow >
                                <GridColumn width='1'>
                                    <Icon name='map marker alternate' size='large' />
                                </GridColumn>
                                <GridColumn width='15'>
                                    <div dangerouslySetInnerHTML={{ __html: dataToDisplay.address }}></div>
                                </GridColumn>
                            </GridRow>
                            <GridRow >
                                <GridColumn width='1'>
                                    <Icon name='mail' size='large' />
                                </GridColumn>
                                <GridColumn width='15'>
                                    <div dangerouslySetInnerHTML={{ __html: dataToDisplay.email }}></div>
                                </GridColumn>
                            </GridRow>
                            <GridRow >
                                <GridColumn width='1'>
                                    <Icon name='phone' size='large' />
                                </GridColumn>
                                <GridColumn width='15'>
                                    <div dangerouslySetInnerHTML={{ __html: dataToDisplay.phoneNumber }}></div>
                                </GridColumn>
                            </GridRow>
                            <GridRow >
                                <GridColumn width='16'>
                                    {!isUndefinedOrNull(dataToDisplay.facebook) &&
                                        <>
                                            <a href={dataToDisplay.facebook} target='_blank' className='colorBlack'><Icon name='facebook official' size='large' /></a>
                                        </>
                                    }
                                    {!isUndefinedOrNull(dataToDisplay.twitter) &&
                                        <>
                                            <a href={dataToDisplay.twitter} target='_blank' className='colorBlack'><Icon name='twitter' size='large' /></a>
                                        </>
                                    }
                                    {!isUndefinedOrNull(dataToDisplay.linkedIn) &&
                                        <>
                                            <a href={dataToDisplay.linkedIn} target='_blank' className='colorBlack'><Icon name='linkedin' size='large' /></a>
                                        </>
                                    }
                                    {!isUndefinedOrNull(dataToDisplay.instagram) &&
                                        <>
                                            <a href={dataToDisplay.instagram} target='_blank' className='colorBlack'><Icon name='instagram' size='large' /></a>
                                        </>
                                    }
                                </GridColumn>
                            </GridRow>
                        </Grid>
                    </div>
                    <div className='divider'></div>
                    <div className='contactForm'>
                        <Form className='adminInputContainer' id='inputForm' autocomplete="off">
                            <FormGroup widths='equal'>
                                <FormInput fluid label='Name' type='text' id='name' placeholder='Enter your name' required />
                                <FormInput fluid label='Email' type='text' id='email' placeholder='Enter your email' required />
                            </FormGroup>
                            <FormGroup widths='equal'>
                                <FormInput fluid label='Phone Number' type='text' id='phone' placeholder='Enter your phone number' required />
                                <FormInput fluid label='Address' type='text' id='address' placeholder='Enter your address' required />
                            </FormGroup>
                            <FormInput fluid label='Subject' tyep='text' id='subject' placeholder='Enter the subject' required />
                            <FormTextArea label='Message' placeholder='Type the message here...' id='message' required />
                        </Form>
                        <FormButton color='olive' content='Send' onClick={() => onSend()} />
                    </div>
                </div >
            </div>
        </>
    );
}

export default Contact;