
import { useContext } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Menu, Segment, Sidebar, SidebarPushable, SidebarPusher } from 'semantic-ui-react';
import './App.css';
import AppFooter from './Components/AppFooter';
import AppHeaders from './Components/AppHeader';
import NavigationList from './Components/NavigationList';
import AreasOfInterest from './Pages/AreasOfInterest';
import Contact from './Pages/Contact';
import Facilities from './Pages/Facilities';
import Gallery from './Pages/Gallery';
import Home from './Pages/Home';
import LatestNews from './Pages/LatestNews';
import GroupMembers from './Pages/GroupMembers';
import Publications from './Pages/Publications';
import RGB from './Pages/RGB';
import { LocalStorage } from './Service/LocalStorage';

function App() {
  const { isSideMenuActive: [isSideMenuActive, setIsSideMenuActive] } = useContext(LocalStorage);

  return (
    <>
      <SidebarPushable as={Segment}>
        <Sidebar
          as={Menu}
          animation='overlay'
          icon='labeled'
          direction='top'
          width='wide'
          inverted
          vertical
          visible={isSideMenuActive}
          onHide={() => setIsSideMenuActive(false)}>
          <NavigationList></NavigationList>
        </Sidebar>

        <SidebarPusher dimmed={isSideMenuActive}>
          <AppHeaders></AppHeaders>
          <div className='pageContainer'>
            <Routes>
              <Route path='/' element={<Navigate to='/home' />} />
              <Route path='/home' element={<Home />} />
              <Route path='/rgb' element={<RGB />} />
              <Route path='/area-of-interest' element={<AreasOfInterest />} />
              <Route path='/publications/:category' element={<Publications />} />
              <Route path='/publications' element={<Publications />} />
              <Route path='/our-research-group/:category' element={<GroupMembers />} />
              <Route path='/our-research-group' element={<GroupMembers />} />
              <Route path='/facilities' element={<Facilities />} />
              <Route path='/contact' element={<Contact />} />
              <Route path='/gallery/:category' element={<Gallery />} />
              <Route path='/gallery' element={<Gallery />} />
              <Route path='/latest-news' element={<LatestNews />} />  
              <Route path='*' element={<Navigate to='/home' />} />           
            </Routes>
          </div>
          <AppFooter></AppFooter>
        </SidebarPusher>
      </SidebarPushable>

    </>
  );
}

export default App;